import {useEffect, useState} from "react";
import {membership_contract} from "../constants/contract";
import {memberShipAbi} from "../constants/memberShipAbi";
import {USER_ADDRESS} from "../constants";
import {nonerdsAbi} from "../constants/nonerdsAbi";
import getDataFromNftraritytool from "../components/functions/getDataFromNftraritytool";
import transferNft from "../components/functions/transferNft";
import {findMethodABI} from "../utilities/util";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Membership = () => {
    const [countMembershipInWallet, setCountMembershipInWallet] = useState(0)
    const [metadata, setMetadata] = useState([])
    const [recipient, setRecipient] = useState('')
    const [current, setCurrent] = useState(0)

    useEffect(async () => {
        if (USER_ADDRESS) {
            await currentPasses();
        }
    }, [])

    const currentPasses = async () => {
        let currentInWallet = await (membership_contract.method(findMethodABI(memberShipAbi, 'walletOfOwner')).call(USER_ADDRESS));
        currentInWallet = currentInWallet.decoded[0].sort(function(a, b){return a - b});
        setCountMembershipInWallet(currentInWallet.length)

        let metas = await getDataFromNftraritytool(currentInWallet, 'UnionMembership', false, false)
        setMetadata(metas)

        setMetadata([...metas])
    }

    const transfer = async () => {
        await transferNft(nonerdsAbi, membership_contract, recipient, metadata[current].result.edition)

        await currentPasses();
    }

    const buildListNavigation = (count, current, onPageChanged) => {
        return <div className="u-absolute-hcenter u-carousel-indicators u-carousel-indicators-1" style={{ bottom: '-40px' }}>
            {
                current >= 2 && <div style={{ paddingLeft: '9px', backgroundColor: '#4d4d4d', color: '#888888', marginLeft: '5px', marginRight: '5px', width: '26px', height: '26px', borderRadius: '13px' }} data-u-slide-to={0} onClick={e => onPageChanged(0)}>1</div>
            }
            {
                current >= 3 && <div style={{ paddingLeft: '9px', color: '#888888', marginLeft: '5px', marginRight: '5px', width: '26px', height: '26px', borderRadius: '13px', alignItems: 'center', marginTop: '-3px' }}>...</div>
            }
            {
                current >= 1 && <div style={{ paddingLeft: '9px', backgroundColor: '#4d4d4d', color: '#888888', marginLeft: '5px', marginRight: '5px', width: '26px', height: '26px', borderRadius: '13px' }} data-u-slide-to={current - 1} onClick={e => onPageChanged(current - 1)}>{current}</div>
            }
            <div style={{ paddingLeft: '9px', backgroundColor: '#4d4d4d', color: 'white', marginLeft: '5px', marginRight: '5px', width: '26px', height: '26px', borderRadius: '13px' }}>{current + 1}</div>
            {
                current <= count - 2 && <div style={{ paddingLeft: '9px', backgroundColor: '#4d4d4d', color: '#888888', marginLeft: '5px', marginRight: '5px', width: '26px', height: '26px', borderRadius: '13px' }} data-u-slide-to={current + 1} onClick={e => onPageChanged(current + 1)}>{current + 2}</div>
            }
            {
                current <= count - 4 && <div style={{ paddingLeft: '9px', color: '#888888', marginLeft: '5px', marginRight: '5px', width: '26px', height: '26px', borderRadius: '13px', alignItems: 'center', marginTop: '-3px' }}><span>...</span></div>
            }
            {
                current <= count - 3 && <div style={{ paddingLeft: '9px', backgroundColor: '#4d4d4d', color: '#888888', marginLeft: '5px', marginRight: '5px', width: '26px', height: '26px', borderRadius: '13px' }} data-u-slide-to={count - 1} onClick={e => onPageChanged(count - 1)}>{count}</div>
            }</div>
    }

    const buildPassesOverview = () => {
        return <div className="u-carousel-inner u-gallery-inner" role="listbox">
            {metadata.map((token, index) =>
                <div key={"pager-"+index} className={"u-carousel-item u-effect-fade u-gallery-item u-carousel-item-"+(index == current ? ' u-active': '')}>
                    <div className="u-back-slide" data-image-width="1131" data-image-height="1600">
                        <LazyLoadImage className="u-back-image u-expanded" loading={"lazy"}
                             src={token.img}/>
                    </div>
                    <div className="u-align-center u-over-slide u-shading u-valign-bottom u-over-slide-1">
                        <h3 key={"h3"+index} className="u-gallery-heading">#{token.result.edition}</h3>
                        <div className="u-gallery-text">
                            {token.result.attributes.map((attribute) =>
                                <div key={index+"-"+attribute.trait_type}>{attribute.trait_type}: {attribute.value}</div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    }

    require('./../Membership.css')
    return <div>
        <section className="u-clearfix membership-u-section-1" id="sec-e109">
            <div className="u-clearfix u-sheet u-valign-middle u-sheet-1">
                <div className="u-border-3 u-border-grey-40 u-container-style u-group u-group-1">
                    <div className="u-container-layout u-valign-middle u-container-layout-1">
                        <h1 className="u-align-center u-custom-font u-text u-text-default u-text-1">Membership passes</h1>
                    </div>
                </div>
            </div>
        </section>
        <section className="u-clearfix membership-u-section-2" id="sec-fc9b">
            <div className="u-clearfix u-sheet u-sheet-1">
                <h2 className="u-custom-font u-text u-text-default u-text-1">You have <b>{countMembershipInWallet}</b> membership pass{countMembershipInWallet > 1 && 'es'}</h2>
                {countMembershipInWallet > 0 &&
                    <div>
                         <div
                    className="u-carousel u-gallery u-gallery-slider u-layout-carousel u-lightbox u-show-text-on-hover u-gallery-1"
                    data-interval="5000" data-u-ride="carousel" id="carousel-45a4" data-pswp-uid="1">
                    {buildListNavigation(countMembershipInWallet, current, num => setCurrent(num))}
                    {buildPassesOverview()}
                    {/**/}
                </div>
                <h1 className="u-custom-font u-text u-text-2">&nbsp;</h1>
                <div className="u-align-center u-expanded-width-xs u-form u-form-1">
                    <form action="#" method="POST" className="u-clearfix u-form-spacing-17 u-form-vertical u-inner-form"
                        style={{padding: '15px'}} source="custom">
                        <div className="u-form-group u-form-name u-label-top">
                            <label htmlFor="name-558c" className="u-label">Send your Membership to someone in need.</label>
                            <input type="text" placeholder="Public Wallet Address" id="name-558c" name="Address"
                                className="u-border-1 u-border-grey-30 u-input u-input-rectangle" required="" onChange={e => setRecipient(e.target.value)}/>
                        </div>
                        <div className="u-form-group u-form-submit">
                            <button className="u-btn u-btn-submit u-button-style" type='button' onClick={e => transfer(e)}>Transfer</button>
                        </div>
                    </form>
                </div>
                    </div>
                }
            </div>
        </section>
    </div>
}

export default Membership
