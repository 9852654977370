import Connex from '@vechain/connex'
import {
    NODE,
    NETWORK,
    MEMBERSHIP_CONTRACT_ADDRESS,
    NO_NERDS_CONTRACT_ADDRESS,
    UNIONTOKEN_CONTRACT_ADDRESS,
    BULLIES_CONTRACT_ADDRESS
} from './'

const connex = new Connex({node: NODE, network: NETWORK})
const membership_contract = connex.thor.account(MEMBERSHIP_CONTRACT_ADDRESS)
const no_nerds_contract = connex.thor.account(NO_NERDS_CONTRACT_ADDRESS)
const union_token_contract = connex.thor.account(UNIONTOKEN_CONTRACT_ADDRESS)
const bullies_contract = connex.thor.account(BULLIES_CONTRACT_ADDRESS)

export {
    connex,
    membership_contract,
    no_nerds_contract,
    union_token_contract,
    bullies_contract
}
