const getDataFromNftraritytool = async (currentInWallet, collection, showRank = true, showRarity = true) => {
    let metas = []
    var formdata = new FormData();
    formdata.append("ids", currentInWallet.join(","))
    formdata.append('collection', collection)

    await fetch("https://nftraritytool.eu/api/rarity", {method: 'POST',body: formdata})
        .then( resp => resp.json())
        .then((data)=> {
            data.map((item) => {
                let attributes = [];
                Object.entries(item['attributes']).map(([key,value])=>{
                    attributes.push({trait_type: key, value: value})
                })
                if (showRank) {
                    attributes.push({trait_type: 'rank', value: item['rank']})
                }
                if (showRarity) {
                    attributes.push({trait_type: 'rarity', value: item['rarity']})
                }
                let result = {
                    attributes: [...attributes],
                    edition: item['id'],
                    name: "#" + item['id']
                }
                metas.push({result, img: item['imageUrl']})
            })
        })

    return metas
}

export default getDataFromNftraritytool;


