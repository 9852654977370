import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Home from './pages/Home'
import NoPage from "./pages/NoPage";
import Roadmap from "./pages/Roadmap"
import Cabinet from "./pages/Cabinet"
import Membership from "./pages/Membership"
import Uniontoken from "./pages/Uniontoken"
import SchoolYard from "./pages/SchoolYard"
import MyCrew from "./pages/MyCrew";
import Header from './components/common/Header'
import Footer from './components/common/Footer'

function App() {
    return (
        <BrowserRouter>
            <Header/>
            <Routes>
                <Route element={< Home />} path='/'/>
                <Route element={< Roadmap />} path='/roadmap'/>
                <Route element={< Cabinet />} path='/cabinet'/>
                <Route element={< Membership />} path='/membership'/>
                <Route element={< MyCrew />} path='/my-crew'/>
                <Route element={< Uniontoken />} path='/unionbank' />
                <Route element={< SchoolYard />} path='/school-yard' />
                <Route path="*" element={< NoPage />}/>
            </Routes>
            <ToastContainer />
            <Footer/>
        </BrowserRouter>
    );
}

export default App;
