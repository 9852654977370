import bullies from "../images/IMG_0837.jpg"

const Roadmap = () => {
    import('./../Roadmap.css')
    return <div>
        <section className="u-align-center u-clearfix u-custom-color-3 roadmap-u-section-1" id="sec-8f74">
            <div className="u-clearfix u-sheet u-valign-middle-md u-sheet-1">
                <h1 className="u-custom-font u-text u-text-default u-text-1">Roadmap 2022</h1>
                <div className="u-expanded-width u-tabs u-tabs-1">
                    <ul className="u-spacing-15 u-tab-list u-unstyled" role="tablist">
                        <li className="u-tab-item" role="presentation">
                            <a className="active u-active-white u-button-style u-custom-color-2 u-tab-link u-tab-link-1"
                               id="link-tab-0da5" href="#tab-0da5" role="tab" aria-controls="tab-0da5"
                               aria-selected="true">Phase 1</a>
                        </li>
                        <li className="u-tab-item" role="presentation">
                            <a className="u-active-white u-button-style u-custom-color-2 u-tab-link u-tab-link-2"
                               id="link-tab-14b7" href="#tab-14b7" role="tab" aria-controls="tab-14b7"
                               aria-selected="false">Phase 2</a>
                        </li>
                        <li className="u-tab-item" role="presentation">
                            <a className="u-active-white u-button-style u-custom-color-2 u-tab-link u-tab-link-3"
                               id="link-tab-2917" href="#tab-2917" role="tab" aria-controls="tab-2917"
                               aria-selected="false">Phase 3</a>
                        </li>
                    </ul>
                    <div className="u-tab-content">
                        <div className="u-container-style u-tab-active u-tab-pane u-white u-tab-pane-1" id="tab-0da5"
                             role="tabpanel" aria-labelledby="link-tab-0da5">
                            <div className="u-container-layout u-container-layout-1">
                                <div
                                    className="u-align-left u-border-3 u-border-grey-40 u-container-style u-expanded-width-sm u-expanded-width-xs u-group u-group-1">
                                    <div className="u-container-layout u-container-layout-2">
                                        <p className="u-text u-text-2">
                                            <span
                                                style={{fontWeight: "bold"}}>Anti-N.E.R.D tablet NFT $UNION airdrop</span>&nbsp;
                                            <br/>
                                            <br/>- AN NFT holders are airdropped 1$UNION token per day per AN NFT, on
                                            a weekly basis&nbsp;<br/>- Airdrop accumulating ‘back-end’ only
                                            during Phase 1&nbsp;<br/>- $UNION tokens accumulated during Phase
                                            1 will be airdropped to wallets during phase 2
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="u-align-left u-border-3 u-border-grey-40 u-container-style u-expanded-width-xs u-group u-group-2">
                                    <div
                                        className="u-container-layout u-valign-middle-lg u-valign-middle-md u-valign-middle-xl u-valign-top-sm u-valign-top-xs u-container-layout-3">
                                        <p className="u-text u-text-3">
                                            <span
                                                style={{fontWeight: "bold"}}> Anti-N.E.R.D tablet (AN) NFT launch&nbsp;</span>
                                            <br/>
                                            <br/>- Supply of 10,000 Anti-N.E.R.D. NFTs&nbsp;<br/>- Minting cost 100VET
                                            per ​Anti-N.E.R.D. NFT&nbsp;<br/>- Full pack defined as 12x
                                            ​Anti-N.E.R.D. NFTs
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="u-align-center-lg u-align-center-md u-align-left-xl u-border-3 u-border-grey-40 u-container-style u-expanded-width-xs u-group u-group-3">
                                    <div className="u-container-layout u-container-layout-4">
                                        <p className="u-align-left-sm u-align-left-xs u-text u-text-4">
                                            <span style={{fontWeight: "bold"}}>Anti-N.E.R.D tablet NFT aftermarket sales redistribution</span>
                                            <br/>
                                            <br/>- 5% of aftermarket AN NFT sales are distributed to holders of Full
                                            Pack (12x) or more AN NFTs&nbsp;<br/>- Share of 5% aftermarket sales
                                            tax is based on market share&nbsp;<br/>- Each pack receives 0.12%
                                            of the 5% aftermarket sales tax
                                        </p>
                                        <p className="u-align-center-sm u-align-center-xs u-text u-text-5">
                      <span className="u-text-palette-1-base">Example: Dr Dick holds 60 Anti-N.E.R.D tablet NFTs
 Aftermarket sales of Anti-N.E.R.D tablets in July total 1.000,000VET
 Dr Dick receives redistribution of 300VET for July (1,000,000VET x 5% x 0.12% x 5) </span>
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="u-border-3 u-border-palette-5-dark-1 u-container-style u-expanded-width-sm u-expanded-width-xs u-group u-group-4">
                                    <div
                                        className="u-container-layout u-valign-middle-lg u-valign-middle-md u-valign-middle-sm u-valign-middle-xs u-container-layout-5">
                                        <p className="u-text u-text-palette-1-base u-text-6"> Example: Dr McVetPants
                                            holds 100 Anti-N.E.R.D tablet NFTs throughout 4 weeks during phase 1
                                            Dr McVetPants accumulates 2800$UNION d (1$UNION x 100NFTs x 28days)
                                            Dr MvVetPants receives all his accumulated $UNION tokens into his wallet
                                            during Phase 2 </p>
                                    </div>
                                </div>
                                <div className="u-image u-image-circle u-preserve-proportions u-image-1" alt=""
                                     data-image-width="300" data-image-height="300"></div>
                            </div>
                        </div>
                        <div className="u-container-style u-tab-pane u-white u-tab-pane-2" id="tab-14b7" role="tabpanel"
                             aria-labelledby="link-tab-14b7">
                            <div className="u-container-layout u-container-layout-6">
                                <div
                                    className="u-border-3 u-border-grey-50 u-container-style u-expanded-width-md u-expanded-width-sm u-expanded-width-xs u-group u-group-5">
                                    <div className="u-container-layout u-container-layout-7">
                                        <p className="u-text u-text-7">
                                            <span
                                                style={{fontWeight: "bold"}}> Union Membership (UM) NFT launch&nbsp;</span>
                                            <br/>
                                            <br/>- Supply of 888 Union Membership
                                            NFTs&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                                            <br/>- Whitelist sale at 600 VET per
                                            NFT&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                            <br/>- Holders of a full pack (12) of Anti-N.E.R.D NFTs qualify
                                            for whitelist
                                            sale&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                                            <br/>- Public sale to follow whitelist sale, if any supply
                                            remains, at 800 VET per NFT
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="u-align-left u-border-3 u-border-grey-50 u-container-style u-expanded-width-md u-expanded-width-sm u-expanded-width-xs u-group u-group-6">
                                    <div
                                        className="u-container-layout u-valign-middle-md u-valign-middle-sm u-valign-middle-xl u-valign-middle-xs u-container-layout-8">
                                        <p className="u-text u-text-8">
                                            <span
                                                style={{fontWeight: "bold"}}>Union Membership (UM) Perks &amp; Benefits</span>&nbsp;
                                            <br/>
                                            <br/> - whitelist for other projects when possible&nbsp;<br/>- share of
                                            revenue generated by No Nerds and the Union
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="u-border-3 u-border-grey-50 u-container-style u-expanded-width-md u-expanded-width-sm u-expanded-width-xs u-group u-group-7">
                                    <div className="u-container-layout u-valign-middle-xl u-container-layout-9">
                                        <p className="u-text u-text-9">
                                            <span
                                                style={{fontWeight: "bold"}}>$UNION Token Front-End Launch</span>&nbsp;
                                            <br/>- ‘Back-end’ accumulation to date airdropped to AN NFT holder’s
                                            wallets&nbsp;<br/>- Weekly airdrops to wallets begin&nbsp;<br/>- One time
                                            airdrop of 25$UNION tokens for holding a full pack (x12) AN
                                            NFTs&nbsp;<br/>- DEX listings
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="u-align-left u-border-3 u-border-grey-40 u-container-style u-expanded-width-md u-expanded-width-sm u-expanded-width-xs u-group u-group-8">
                                    <div className="u-container-layout u-container-layout-10">
                                        <p className="u-text u-text-10">
                                            <span style={{fontWeight: "bold"}}>Union Membership NFT aftermarket sales redistribution</span>
                                            <br/>
                                            <br/>- 5% of aftermarket UM NFT sales are redistributed.<br/>- ​15% of
                                            union revenue redistributed&nbsp;<br/> - 1 union token per day per
                                            NFT for 3 Years !&nbsp;
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="u-container-style u-tab-pane u-white u-tab-pane-3" id="tab-2917" role="tabpanel"
                             aria-labelledby="link-tab-2917">
                            <div className="u-container-layout u-container-layout-11">
                                <div
                                    className="u-border-3 u-border-grey-50 u-container-style u-expanded-width-md u-expanded-width-sm u-expanded-width-xs u-group u-group-9">
                                    <div className="u-container-layout u-valign-middle u-container-layout-12">
                                        <p className="u-text u-text-default u-text-11">
                                            <span style={{fontWeight: "bold"}}> Bullys (BL) NFT launch</span>
                                            <br/>
                                            <br/>- Supply of 4444 BL NFTs<br/>
                                            - 1st Tier Whitelist sale at
                                            600 VET per BL NFT for holders of full pack of Anti N.E.R.D tablet
                                            NFTs
                                            AND Union Membership NFT<br/>
                                            - 2nd Tier Whitelist sale at
                                            800 VET per BL NFT for holders of full pack of Anti N.E.R.D
                                            tablet NFTs<br/>
                                            - Holders of full pack of Anti N.E.R.D
                                            tablet NFTs (x12) qualify for whitelist<br/>
                                            - Public
                                            sale to follow whitelist sale, if any supply remains, at
                                            1200 VET per BL NFT
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="u-border-3 u-border-grey-50 u-container-style u-expanded-width-lg u-expanded-width-md u-expanded-width-sm u-expanded-width-xs u-group u-group-10">
                                    <div className="u-container-layout u-container-layout-13">
                                        <p className="u-text u-text-default-xl u-text-12">
                                            <span
                                                style={{fontWeight: "bold"}}>Bullys NFT aftermarket sales redistribution</span>&nbsp;
                                            <br/>
                                            <br/>-10% of aftermarket BL NFT sales are redistributed to holders&nbsp;
                                            <br/>- Share of 10% aftermarket sales tax is based on market
                                            share&nbsp;<br/>- Each BL NFT is worth 0.01% of the 10%
                                            aftersales tax
                                        </p>
                                        <p className="u-text u-text-palette-1-base u-text-13"> Example: Dr VeChain4Lambo
                                            holds 10 Bullys NFT
                                            Aftermarket sales of Bullys NFTs in July total 3,000,000VET
                                            Dr Vechain4Lambo receives redistribution of 300VET for July</p>
                                    </div>
                                </div>
                                <img className="u-image u-image-default u-preserve-proportions u-image-2"
                                     src={bullies} alt="" data-image-width="915"
                                     data-image-height="915"/>
                                <div
                                    className="u-border-3 u-border-grey-50 u-container-style u-expanded-width-lg u-expanded-width-md u-expanded-width-sm u-expanded-width-xs u-group u-group-11">
                                    <div className="u-container-layout u-container-layout-14">
                                        <p className="u-text u-text-14">
                                            <span style={{fontWeight: "bold"}}>Bullys NFT $UNION airdrop</span>&nbsp;
                                            <br/>
                                            <br/>- BL NFT holders are aidropped 1 $UNION token per day per BL
                                            NFT, on a weekly basis&nbsp;<br/>
                                            <br/>
                                            <span style={{fontWeight: "bold"}}>Continuous reward incentives for Union holders</span>&nbsp;
                                            <br/>
                                            <br/>- Details TBA
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>;
}

export default Roadmap;
