const NoPage = () => {
    return <div className="container">
        <div className="card">
            <div className="card-body">
                <div className="container">
                    <h4>404</h4>
                </div>
            </div>
        </div>
    </div>
};

export default NoPage;
