const PROJECT_NAME = 'NoNerdsInc'

// mainnet
const NETWORK = 'main'
const NODE = 'https://mainnet.veblocks.net/'
const MEMBERSHIP_CONTRACT_ADDRESS = '0x2f478c2e68e3385e632C625F0ee12D5a3A775e68'
const NO_NERDS_CONTRACT_ADDRESS = '0x77FE6041fA5beb0172C9aB6014b4D8d5099F0A23'
const UNIONTOKEN_CONTRACT_ADDRESS = '0x34109fc2a649965eecd953d31802c67dcc183d57'
const BULLIES_CONTRACT_ADDRESS = '0xc9c8964cf25d2c738190f74b8508cdfac8650b9d'
//
// testnet
// const NETWORK = 'test'
// const NODE = 'https://testnet.veblocks.net/'
// const MEMBERSHIP_CONTRACT_ADDRESS = '0xF54bf8717e00B785296af95F3938C8B4d4ebA54E'
// const NO_NERDS_CONTRACT_ADDRESS = '0xbD02a931F90E52Fb8987fa4e70D31730Cb6692ae'
// const UNIONTOKEN_CONTRACT_ADDRESS = '0xEA283edCfDb17a8a7537F8a6a84c4bd1C21d608D'
// const BULLIES_CONTRACT_ADDRESS = '0x7f44a400b54e266916d8f96f04068864181dc4b3'

const NFTRARITYTOOL = true
const ADDRESS_KEY = 'wallet';

const USER_ADDRESS = window.localStorage.getItem(ADDRESS_KEY);

export {
    NODE,
    NETWORK,
    PROJECT_NAME,
    MEMBERSHIP_CONTRACT_ADDRESS,
    NO_NERDS_CONTRACT_ADDRESS,
    UNIONTOKEN_CONTRACT_ADDRESS,
    BULLIES_CONTRACT_ADDRESS,
    NFTRARITYTOOL,
    USER_ADDRESS,
    ADDRESS_KEY
}
